import React from 'react'

type Props = {}

const SignalementDescription = (props: Props) => {
  return (
    <div className="text-justify bg-white mb-6 sm:mb-0 basis-1/2 p-5">
    <h2 className="uppercase font-extrabold mb-5 bg-white">lorem ipsum</h2>
    <p className="mb-3 bg-white">
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium
      incidunt consectetur voluptatem beatae earum odio quo, odit quam quos
      praesentium cupiditate quisquam molestiae ullam asperiores sequi modi
      rerum, optio laudantium! Lorem ipsum dolor, sit amet consectetur
      adipisicing elit. Accusantium incidunt consectetur voluptatem beatae
      earum odio quo, odit quam quos praesentium cupiditate quisquam
      molestiae ullam asperiores sequi modi rerum, optio laudantium! Lorem
      ipsum dolor, sit amet consectetur adipisicing elit. Accusantium
      incidunt consectetur voluptatem beatae earum odio quo, odit quam quos
      praesentium cupiditate quisquam molestiae ullam asperiores sequi modi
      rerum, optio laudantium!
    </p>
    <p className="bg-white mb-5">
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium
      incidunt consectetur voluptatem beatae earum odio quo, odit quam quos
      praesentium cupiditate quisquam molestiae ullam asperiores sequi modi
      rerum, optio laudantium! Lorem ipsum dolor, sit amet consectetur
      adipisicing elit. Accusantium incidunt consectetur voluptatem beatae
      earum odio quo, odit quam quos praesentium cupiditate quisquam
      molestiae ullam asperiores sequi modi rerum, optio laudantium! Lorem
      ipsum dolor, sit amet consectetur adipisicing elit. Accusantium
      incidunt consectetur voluptatem beatae earum odio quo, odit quam quos
      praesentium cupiditate quisquam molestiae ullam asperiores sequi modi
      rerum, optio laudantium!
    </p>
    <a href="/">retour</a>
  </div>
  )
}

export default SignalementDescription