import './Homepage.css';
import logo from '../assets/oremis_bleu.png'

type Props = {}

const Homepage = (props: Props) => {
  return (
    <div className="container p-5 sm:w-3/5 m-auto">
      <img src={logo} alt='oremis_bleu' className="w-36 m-auto sm:w-44"/>
      <h1 className="text-2xl font-bold mb-5 text-center sm:text-4xl">
        Bienvenue sur la plateforme de signalement OREMIS
      </h1>
      <p className="text-justify text-sm mb-5 sm:text-lg">
        Il est crucial de ne pas garder le silence face au harcèlement scolaire
        pour plusieurs raisons. Tout d'abord, le silence peut permettre au
        harcèlement de se perpétuer et de s'aggraver, affectant sérieusement la
        santé mentale, émotionnelle et même physique de la victime.
      </p>
      <p className="text-justify text-sm mb-5 sm:text-lg">
        Le harcèlement peut entraîner des problèmes de confiance en soi,
        d'anxiété, de dépression, voire de pensées suicidaires chez la victime.
        En outre, il crée un climat toxique qui peut nuire au bien-être de tous
        les élèves de l'école, même de ceux qui ne sont pas directement
        impliqués. Faire un signalement est essentiel pour mettre fin au
        harcèlement et protéger la victime. Cela peut sembler difficile, car
        cela peut impliquer de sortir de sa zone de confort ou de craindre des
        représailles de la part des harceleurs, mais c'est une étape cruciale
        pour briser le cycle de la violence.
      </p>
      <p className="text-justify text-sm mb-5 sm:text-lg">
        Signaler le harcèlement permet d'alerter les adultes responsables, tels
        que les enseignants, les conseillers scolaires ou les parents, qui
        peuvent intervenir pour mettre un terme aux comportements abusifs et
        offrir un soutien à la victime. En outre, signaler le harcèlement envoie
        un message clair selon lequel ce type de comportement n'est pas toléré
        dans la société. Cela peut encourager d'autres victimes à parler et à
        chercher de l'aide, tout en dissuadant les harceleurs potentiels en leur
        faisant comprendre qu'il y a des conséquences à leurs actions.
      </p>
      <a href="/signalements">Effectuer un signalement</a>
    </div>
  );
}

export default Homepage;
