import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';

import Homepage from './pages/Homepage';
import Signalements from './pages/Signalements';


const router = createBrowserRouter([{
  path: '/',
  element: <Homepage/>
},{
  path:'/signalements',
  element: <Signalements/>
}])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


