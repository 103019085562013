import React, { useState } from "react";
import SignalementDescription from "../components/SignalementDescription";
import axios from 'axios'; 

type FormData = {
  nom: string;
  prenom: string;
  date_naissance: Date | null;
  email: string;
  telephone: string;
  lieu_etablissement: string;
  description: string;
};

const Signalements = () => {
  const [formData, setFormData] = useState<FormData>({
    nom: "",
    prenom: "",
    date_naissance: null,
    email: "",
    telephone: "",
    lieu_etablissement: "",
    description: "",
  });

  const [status, setStatus] = useState<string | null>(null); // For displaying the result status

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();

    // API call to submit form data
    try {
      const response = await axios.post('https://api-scrutin.oremis.fr/report-harassment', {
        nom: formData.nom,
        prenom: formData.prenom,
        date_naissance: formData.date_naissance,
        email: formData.email,
        telephone: formData.telephone,
        lieu_etablissement: formData.lieu_etablissement,
        description: formData.description,
      });

      if (response.status === 200) {
        setStatus('success');
      } else {
        setStatus('error');
      }
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      setStatus('error');
    }
  }

  return (
    <div className="form_container flex flex-col w-full mx-auto p-2 mt-2 md:flex-row md:w-full lg:w-3/4 xl:w-1/2">
      <SignalementDescription />  
      <form onSubmit={handleSubmit} className="p-6 bg-white basis-1/2">
        <fieldset className="mb-4 bg-white">
          <legend className="text-lg font-semibold bg-white">
            Informations personnelles
          </legend>
          <div className="mb-2 bg-white">
            <label htmlFor="nom" className="bg-white">Nom</label>
            <input
              type="text"
              value={formData.nom}
              onChange={handleChange}
              id="nom"
              name="nom"
              className="w-full px-2 border rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <div className="mb-2 bg-white">
            <label htmlFor="prenom" className="bg-white">Prénom</label>
            <input
              type="text"
              value={formData.prenom}
              onChange={handleChange}
              id="prenom"
              name="prenom"
              className="w-full px-2 border rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <div className="mb-2 bg-white">
            <label htmlFor="date_naissance" className="bg-white">Date de naissance</label>
            <input
              type="date"
              onChange={handleChange}
              id="date_naissance"
              name="date_naissance"
              className="w-full px-2 border rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
        </fieldset>
        <fieldset className="mb-4 bg-white">
          <legend className="text-lg font-semibold bg-white">Contact</legend>
          <div className="mb-2 bg-white">
            <label htmlFor="telephone" className="bg-white">Téléphone</label>
            <input
              type="tel"
              value={formData.telephone}
              onChange={handleChange}
              id="telephone"
              name="telephone"
              className="w-full px-2 border rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <div className="mb-2 bg-white">
            <label htmlFor="email" className="bg-white">Email</label>
            <input
              type="email"
              value={formData.email}
              onChange={handleChange}
              id="email"
              name="email"
              className="w-full px-2 border rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <div className="mb-2 bg-white">
            <label htmlFor="lieu_etablissement" className="bg-white">Etablissement</label>
            <input
              type="text"
              value={formData.lieu_etablissement}
              onChange={handleChange}
              id="lieu_etablissement"
              name="lieu_etablissement"
              className="w-full px-2 border rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
        </fieldset>

        <fieldset className="mb-4 bg-white">
          <legend className="text-lg font-semibold bg-white">Description</legend>
          <div className="mb-2 bg-white">
            <textarea
              name="description"
              cols={20}
              rows={10}
              value={formData.description}
              onChange={handleChange}
              className="w-full px-2 border rounded-md focus:outline-none focus:border-blue-500 bg-white"
            ></textarea>
          </div>
        </fieldset>

        <button type="submit" className="mt-9">Signaler</button>

        {/* Display submission status */}
        {status === 'success' && <p className="text-green-500">Signalement envoyé avec succès !</p>}
        {status === 'error' && <p className="text-red-500">Erreur lors de l'envoi du signalement.</p>}
      </form>
    </div>
  );
};

export default Signalements;